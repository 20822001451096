.employe_header_button {
  width: 160px;
  padding: 10px 16px;
  // margin-right: 40px;
  color: var(--Base-White, #fff);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Brand-600, #7f56d9);
  background: var(--Brand-600, #7f56d9);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.employe_header_button-white {
  margin: 0;
  align-items: center;
  min-width: 160px;
  padding: 10px 16px;
  margin-right: 40px;
  color: var(--Gray-700, #344054);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  svg {
    margin-right: 10px;
    // transition: 0.9s ease all;
  }
  transition: 0.1s ease all;
}
.button-hover {
  &:hover {
    color: var(--Gray-700, #fff);
    // transition: 0.5s ease all;
    background: var(--Base-White, #7f56d9);
    transition: 0.5s ease all;

    svg > path {
      stroke: #fff;
      transition: 0.5s ease-out all;
    }
    transition: 0.5s ease all;
  }
  // transition: 1s ease-out all;
}
.react-datepicker__close-icon::after {
  position: absolute;
  top: 16px;
  left: -60px;
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

// -----------------------------------------Modal style-----------------------------------

.add_modal_title {
  margin: 0;
  padding: 0;
  margin-top: 16px;
  color: var(--Gray-900, #101828);
  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.add_modal_text {
  color: var(--Gray-600, #475467);
  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.add_modal_form {
  margin-top: 20px;
}
.add_modal_form > label {
  display: block;
  margin-bottom: 16px;
}
.add_modal_input_text {
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
  color: var(--Gray-700, #344054);
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.add_modal_input {
  width: 100%;
  padding: 10px 14px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
}
.add_modal_button {
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Brand-600, #7f56d9);
  background: var(--Brand-600, #7f56d9);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #fff);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.delete_modal {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.delete_modal_button-cancel {
  width: 48%;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.delete_modal_button-delete {
  width: 48%;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Error-600, #d92d20);
  background: var(--Error-600, #d92d20);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #fff);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.add_modal_input_checkbox {
  display: flex;
  justify-content: space-between;
  width: 47%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--Base-White, #fff);
}
.add_modal_input_checkbox input[type="checkbox"]:checked + label {
  border-color: red; /* Border rangini o'zgartirish */
}

.employe_header_button {
  width: 127px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.gfEToh > span > img {
  border-radius: 50%;
  height: 40px;
}

.ldFbGy > th {
  font-size: 14px;
}

.igdSCi > th {
  font-size: 14px;
}

.sc-gsnTZi > th {
  font-size: 13px;
}

.eCHvUz {
  font-size: 15px !important;
}

.dark__mode {
  .employee__mode div {
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .kXIZpd {
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .gfEToh {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .employee__modes table > tr {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .employee__modes .table_header {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 10px 10px 0px 0px;
  }

  .add_modal_text,
  .add_modal_input_text {
    color: gray;
  }

  .employee__modes > div {
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    border-radius: 14px;
  }
}
.employee__modes .MuiButton-sizeMd {
  border: none !important;
}

.emp_b {
  padding: 24px 24px 0px 24px;
}

.grtqHS {
  border-bottom: 0;
}

.MuiButton-root {
  transition: 0.2s ease;
  // border: none !important;
}

.employee__modes .modal_overlay {
  z-index: 1000;
}
