.modal_overlay {
  position: absolute;
  background-color: rgba($color: #000, $alpha: 0.75);
}
.modal_overlay_main {
  width: 1112px;
  padding: 20px;
  border-radius: var(--radius-xl, 12px);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  z-index: 10000;
}
.modal_overlay_main_none {
  display: none;
}
.modal_overlay_header {
  display: flex;
  justify-content: space-between;
}
.modal_overlay_X {
  border: none;
  background-color: #fff;
}


