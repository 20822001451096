.table_header {
  // height: 40vh;
  display: flex;
  padding: 0;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
}
.table_header h3 {
  margin: 0;
  padding: 0;
  color: var(--Gray-900, #101828);
  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.table_header p {
  margin: 0;
  padding: 0;
  margin-left: 8px;
  padding: 2px 8px;
  color: var(--Brand-700, #6941c6);
  text-align: center;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 16px;
  border: 1px solid var(--Brand-200, #e9d7fe);
  background: var(--Brand-50, #f9f5ff);
}
tr:nth-child(even) {
  background: var(--Gray-50, #f9fafb);
}
th,
td {
}
td p {
  display: flex;
  align-items: center;
  margin: 0;
}
td p img {
  margin-right: 12px;
}

.students_forms_title {
  padding: 0px 0px 17px 0;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
}

.students_forms_box1 {
  display: flex;
  align-items: start;
  justify-content: space-between !important;
  padding: 15px 0 0px 0 !important;
  margin: 0;
  padding: 38px 0px 10px 0;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
}

.students_forms_boxTitle {
  margin: 0;
  size: 16px;
  line-height: 24px;
}

.students_forms_inputBox {
  display: flex;
  gap: 24px;
  margin-bottom: 15px;
}

.students_forms_label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.students_form_inputs {
  width: 320px;
  margin-top: 6px;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  color: #fff;
  outline: none;
}

.logo_student {
  border-radius: 50%;
  height: 40px;
}