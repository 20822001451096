.home_card {
  // position: relative;
  width: 38vh;
  list-style: none;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 24px;
  transition: 0.2s ease;
}
.home_card > div {
  display: flex;
  align-items: center;
}
.home_card > div > p {
  margin: 0;
  padding: 0;
}
.home_card > div > img {
  margin-right: 16px;
}
.home_card > p {
  padding: 0;
  margin: 24px 0 0 0;
  color: var(--Gray-900, #101828);
  /* Display xs/Semibold */
  font-family: Inter, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.card__pin {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(45deg);
  border: 1px transparent;
  outline: none;
  background: none;
  cursor: pointer;
  transition: 0.4s ease;
}

.btn:active {
  transition: 0.4s ease;
  border: 1px transparent;
}
.btn:focus {
  transition: 0.4s ease;
  border: 1px transparent;
}

.card__pin {
  width: 20px;
  height: 20px;
  outline: none;
}
