.settings_header_img {
  width: 100%;
  border-radius: 15px;
}
.settings_header {
  position: relative;
  top: -40px;
  display: flex;
  align-items: center;
  // background-color: rgb(241, 183, 183);
  padding: 0 32px;
}
.settings_header_person {
  width: 160px;
  height: 160px;
}
.settings_header_bottom {
  margin-left: 24px;
}
.settings_header_title {
  margin: 0;
  color: var(--Gray-900, #101828);
  /* Display sm/Semibold */
  font-family: Inter, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
}
.settings_header_text {
  margin: 0;
  color: var(--Gray-600, #475467);
  /* Text md/Regular */
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.setting_person_info {
  display: flex;
  justify-content: space-between;
}
.setting_person_info_left {
}
.setting_person_info_left_title {
  margin: 0;
  color: var(--Gray-700, #344054);
  /* Text sm/Semibold */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.setting_person_info_left_text {
  margin: 0;
  color: var(--Gray-600, #475467);
  /* Text sm/Regular */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.setting_person_form {
  margin: 0;
  width: 65%;
  padding: 24px;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.setting_person_form_top {
  display: flex;
  justify-content: space-between;
}
.setting_person_form_top > label {
  width: 100%;
}
.setting_person_form_top > label:first-child {
  margin-right: 24px;
}
.setting_person_form_top > label > p {
  margin: 0 0 5px;
  color: var(--Gray-700, #344054);
  /* Text sm/Medium */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.setting_person_form_top > label > input {
  width: 100%;
  padding: 10px 14px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background-color: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.setting_person_form_center {
  cursor: pointer !important;
  display: flex;
  margin-top: 24px;

  & > label {
    cursor: pointer !important;
  }
}
.setting_person_form_center > img {
  width: 64px;
  height: 54px;
  margin-right: 20px;
}
.setting_person_form_center label {
  width: 100%;
}
.settings_dowload_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: auto;
  padding: 16px 24px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
  cursor: pointer;
}
.setting_dowload_img {
  width: 40px;
}
.settings_dowload_p {
  margin: 0;
  color: #888;
  font-family: Rubik, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.setting_person_form_bottom {
  display: flex;
  justify-content: end;
  margin-top: 24px;
}
.setting_person_form_bottom > button {
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  /* Text sm/Semibold */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.setting_person_form_bottom > button:nth-child(2) {
  margin-left: 14px;
  color: var(--Base-White, #fff);
  border: 1px solid var(--Brand-600, #7f56d9);
  background: var(--Brand-600, #7f56d9);
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.setting_bottom_password {
}
.setting_password_ {
  margin-top: 30px;
}
.setting_password_title {
  margin: 0;
  color: var(--Gray-900, #101828);
  /* Text lg/Semibold */
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.setting_setting_text {
  overflow: hidden;
  color: var(--Gray-600, #475467);
  text-overflow: ellipsis;
  /* Text sm/Regular */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.setting_password_text {
  display: flex;
  margin-top: 24px;
  margin-right: 20%;
  justify-content: space-between;
}
.setting_password_text > input,
.setting_new_password_div > input {
  width: 56%;
  padding: 10px 14px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.setting_new_password_div > input {
  width: 100%;
}
.setting_current_password_text {
  // margin-right: 25%;
  color: black;
  /* Text sm/Semibold */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.setting_new_password {
}
.setting_new_password_text {
}
.setting_new_password_div {
  width: 56%;
}
.setting_new_password_div_text {
  margin: 0;
  color: var(--Gray-600, #475467);
  /* Text sm/Regular */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.setting_confirmt_password {
}
.setting_confirm_password_text {
}

.sidebar_bottom_bot > div > img {
  border-radius: 50%;
}

.settings_header_person {
  border-radius: 50%;
}

.setting_person_form_center > img {
  border-radius: 50%;
}

.setting_person_form_center {
  cursor: pointer;
  text-align: center;

  & > label {
    border-radius: 12px;
    border: 2px solid var(--Gray-200, #eaecf0);
    background: var(--Base-White, #fff);
    padding: 16px;
  }
}

.settings_download_ps {
  margin-bottom: 0;
  color: var(--Brand-700, #6941c6);

  /* Text sm/Semibold */
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.settings_download_p {
  margin-bottom: 0;
}

.setting_download_img {
  margin: 0 auto 12px auto;
}

.setting_person_form_bottom {
  padding: 18px 0 0 0;
  border-top: 2px solid #eaecf0;
}
.setting_password_title {
  color: var(--Gray-900, #101828);

  /* Text lg/Semibold */
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px; /* 155.556% */
}

.settingsBox {
  background: #fcfcfd;
}

.dark__mode {
  .settings_header_person {
    border-radius: var(--radius-full, 9999px);
    background: var(lightgray 50% / cover no-repeat);

    /* Shadows/shadow-lg */
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  .settings_header_title,
  .settings_header_text,
  .setting_person_info_left_title {
    color: white;
  }

  .settings_header_text,
  .setting_person_info_left_text {
    color: var(--colors-text-text-tertiary-600, #94969c);

    /* Text md/Regular */
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .setting_password_title {
    color: var(--colors-text-text-primary-900, #f5f5f6);

    /* Text lg/Semibold */
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
  .setting_password_text {
    overflow: hidden;
    color: var(--colors-text-text-tertiary-600, #8d8d8d);
    text-overflow: ellipsis;

    /* Text sm/Regular */
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .setting_setting_text {
    overflow: hidden;
    color: gray;
    text-overflow: ellipsis;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .setting_person_form {
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .setting_person_form_top p {
    color: var(--colors-text-text-secondary-700, #cecfd2);
    margin: 0 0 6px 0;
    /* Text sm/Medium */
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .setting_person_form_top input {
    overflow: hidden;
    color: var(--colors-text-text-primary-900, #f5f5f6);
    text-overflow: ellipsis;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background-color: var(--Colors-Background-bg-primary, #0c111d) !important;

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .setting_person_form_center label {
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);
  }

  .setting_dowload_img {
    border-radius: var(--radius-md, 8px);
    border: 3px solid
      var(
        --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
        #333741
      );

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .setting_person_form_bottom {
    border-top: 1px solid var(--Colors-Border-border-secondary, #1f242f);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .setting_password_text input {
    overflow: hidden;
    color: var(--Colors-Text-text-placeholder, #85888e);
    text-overflow: ellipsis;

    /* Text md/Regular */
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .setting_person_form_bottom button:nth-child(1) {
    background-color: transparent;
    color: white;
    transition: 0.2s ease;

    &:hover {
      background-color: #1f242f;
      border: 1px solid rgba(168, 168, 168, 0.425);
    }
  }
  .setting_current_password_text {
    color: white;
  }
}
