@media (min-width: 1519px) {
  .consultantBox > div:first-child > div:first-child {
    overflow-y: scroll;
    height: 343px;
  }
  .consultantBox {
    margin-bottom: 0;
  }
}

@media (min-width: 1688px) {
  .consultantBox > div:first-child > div:first-child {
    overflow-y: scroll;
    height: 420px;
  }
  .consultantBox {
    margin-bottom: 0;
  }
}

@media (min-width: 1920px) {
  .consultantBox > div:first-child > div:first-child {
    overflow-y: scroll;
    height: 515px;
  }
  .consultantBox {
    margin-bottom: 0;
  }
}

/* Style for smaller viewports */
@media (min-width: 2048px) {
  .consultantBox > div:first-child > div:first-child {
    overflow-y: scroll;
    height: 539px;
  }
  .consultantBox {
    margin-bottom: 0;
  }
}


.con {
    border-top: none !important;
}