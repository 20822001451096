.containerH {
  padding: 24px 24px 18px 24px;
}

.home_header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  background-color: #fff;
  align-items: center;
}

.home_header > h2 {
  margin: 0;
  color: #000;
  /* Display xs/Medium */
  font-family: Inter, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.home_header > div > select {
  margin-right: 10px;
  padding: 10px 30px !important;
  background: var(--Base-White, #fff);
  background-repeat: no-repeat;
  background-position: 8px center;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  user-select: none;
  cursor: pointer;
}

.home_header > div > select:nth-child(1) {
  background-image: url("../../../Img/filter-funnel.svg");
}

.home_header > div > select:nth-child(2) {
  background-image: url("../../../Img/filter-lines.svg");
}

.home_header > div > select:focus {
}

.home_header > div > select > option {
  color: var(--Gray-700, #344054);
  /* Text md/Semibold */
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;

  &:hover {
    border-radius: var(--radius-sm, 6px);
    background: var(--Gray-50, #f9fafb);
    background-color: #000;
  }
}

.header_select:focus {
  outline: none;
}

.header_option:focus {
  background-color: rgba(64, 189, 7, 0.05);
}

.header_select {
  /* Example border */
  width: 163px !important;
  padding: 10px 30px !important; /* Example padding */
  font-size: 16px; /* Example font size */
  border-radius: 5px;
  background-color: #ffffff !important; /* Example rounded corners */
}

.header_option > span {
  display: block;
  padding: 9px 10px !important;
  width: 200px;
  appearance: none; /* Hide default browser styles */
}

.header_option {
  color: #333; /* Example text color */
  padding: 5px 10px; /* Example padding */
  font-size: 16px; /* Example font size */
  background-color: #fff; /* Example background color */
  cursor: pointer;

  &span {
    background-color: #ad3f3f !important;
    color: red !important; /* Example hover effect */
    cursor: pointer;
  }
}

.home_header > div > label {
  position: relative;
}

.home_header > div > label > input {
  width: 292px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 10px 14px 10px 40px;

  &:focus {
    outline: none;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
}

.home_header > div > label > img {
  position: absolute;
  top: 12px;
  left: 11px;
  z-index: 0;
}

.home_span {
  margin: 18px 0 0;
  height: 1px;
  background-color: #eaecf0;
}

.home_item {
  padding: 0;
  margin: 32px 0 0;
  display: flex;
  justify-content: space-between;
}


.pagination {
  width: 79%;
  position: fixed;
  bottom: 0;
  border-top: 1px solid var(--Gray-200, #eaecf0);
  padding: 12px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 0;
}

.sidebar {
  width: 5%;
  position: fixed;
  left: 0;
  gap: 98px;
}

.admin {
  padding: 0 0 0 19%;
}

.dark__mode {
  background-color: var(--Colors-Background-bg-primary_alt, #161b26) !important;

  & .header_select {
    background-repeat: no-repeat !important;
    border-radius: 8px !important;
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
    background-color: var(
                    --Colors-Background-bg-primary_alt,
                    #161b26
    ) !important;
    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
    color: white !important;
  }

  & .search__mode {
    color: white;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_alt, #161b26);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  & .MuiPaginationItem-previousNext,
  .MuiPaginationItem-circular {
    color: white !important;
  }

  & .pagination {
    background: var(--Colors-Background-bg-primary, #0c111d);
  }

  .bSTTfg {
    background: var(--Colors-Background-bg-primary, #0c111d);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
  }

  .students__text {
    color: white !important;
  }

  tr:nth-child(even) {
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
  }

  .pagination {
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    margin: 0;
  }

  .student__table table tr td {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  .student__table .dark__mode .dYvkiF {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  .student__table .table_header {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  .student__table .dYvkiF {
    background: var(--Colors-Background-bg-secondary, #161b26);
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f);
  }

  td {
    color: white;
  }

  .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: rgb(255 255 255 / 10%) !important;
  }

  .header_option {
    background-color: #161b26;
  }
}

.modalls {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.cLDIPQ {
  border: 2px solid #dfdfdf;
  border-radius: 10px;
}

.bigBox {
  position: relative;
}

.dark__mode {
  .bigBox .table_header {
    border-radius: var(--radius-xl, 12px 12px 0 0);
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .bVsbyZ {
    border: transparent;
  }

  .dYvkiF {
    border: transparent;
  }

  .bigBox tr {
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-secondary, #161b26);
  }

  .jLrbhg {
    border-radius: var(--radius-xl, 12px);
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .app__inp {
    overflow: hidden;
    color: var(--colors-text-text-primary-900, #f5f5f6);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_alt, #161b26);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .application .control_item {
    margin: 0 !important;
  }

  .application .ftoEMA {
    border: 2px solid var(--Colors-Border-border-primary, #333741);
  }

  .application .oXEbd {
    border: 2px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_alt, #161b26);
  }

  .application .gdJKzM {
    border: 2px solid var(--Colors-Border-border-primary, #333741) !important;
    background: var(--Colors-Background-bg-primary_alt, #161b26) !important;
  }

  .bigBox .geMVSZ {
    border: 2px solid var(--Colors-Border-border-primary, #333741) !important;
  }

  .control_link {
    background-color: transparent !important;
  }
}

.assign-button-box {
  //background-color: #0d1fc0;
  display: flex;
  width: 90%;
  height: 40px;
  margin: 0 auto;
  align-items: center;

  li {
    background-color: #F9FAFB;
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--Colors-Border-border-secondary, #7F56D9);
    cursor: pointer;
  }

  .active {
    background-color: #7F56D9;
    color: white;
  }
}

.sms-auth-data-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;

  .top-box {
    width: 900px;

    .label-box label{
      width: 50%;
      font-size: 20px;
      font-family: revert;
    }

    .input-box input{
      width: 40%;
      border: 1px solid #a5a6a8;
      padding: 10px;
      border-radius: 7px;
    }
    .input-box .input-2{
      margin-left: 10%;
    }
  }

  .btn-box{
    width: 725px;
    display: flex;
    justify-content: end;

    button{
      padding: 10px 38px;
      border-radius: 7px;
      font-size: 17px;
      margin-top: 16px;
      border: 1px solid #A996DA;
    }

    button:hover{
      color: var(--Gray-700, #fff);
      background: var(--Base-White, #7f56d9);
      transition: 0.5s ease all
    }
  }
}


