.react-kanban-board {
  width: 25%;
  padding: 24px;
  margin-right: 10px;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid #eaecf0;
  background: var(--Warning-25, #fffcf5);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  max-height: calc(100vh - 140px);
  overflow: auto !important;
}

.react-kanban-card__title {
  //color: var(--Gray-900, #101828);
  /* Text md/Semibold */
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  color: #0d1fc0 !important;
}
.kanban_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.kanban_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
  padding: 8px 16px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}
.kanban_item > div {
  display: flex;
  align-items: center;
}
.kanbant_item_left_img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.kanbant_text {
  padding: 0;
  margin: 0;
  color: var(--Gray-700, #344054);
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.kanbant_item_right_img {
  width: 20px;
}

.textarea__form {
  color: #fff !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.textarea-animation {
  animation: fadeIn 0.5s ease-in-out;
}

.title__kanba {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #101828;
  width: 100%;
  text-align: center;
  border-radius: var(--radius-md, 8px);
  border-top: 1px solid var(--Colors-Border-border-brand-solid_alt, #979a9f);
}

.card__title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}

.sets{
  border: 1px solid red;
}

.custom-card {
  background: #fff;
  padding: 8px 8px 8px 16px;
  margin-top: 10px;
  width: 240px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  position: relative;
  background: var(--Base-White, #fff);
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-brand-solid_alt, #D0D5DD);
}

.card__text {
  margin-bottom: 0;
}


.react-kanban-column:nth-child(odd) {
  border-radius: var(--radius-xl, 12px);
  border: 1px solid #eaecf0;
  background: var(--Warning-25, #fffcf5);

  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.react-kanban-column{
  width: 250px;
  padding: 0 !important;
}

.react-kanban-column:nth-child(even) {
  border-radius: var(--radius-xl, 12px);
  border: 1px solid #eaecf0;
  background: var(--Success-25, #f6fef9);

  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.react-kanban-board {
  padding: 0 0 28px 0 !important;
}

// DARKMODE

.dark__mode {
  .react-kanban-column {
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .react-kanban-column > div > div > div {
    color: var(--colors-text-text-tertiary-600, #94969c);
    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .card__title,
  .card__text {
    color: var(--colors-text-text-primary-900, #f5f5f6);
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .form-control {
    background-color: white !important;
    color: black !important;
  }

  .tri {
    top: 22px;
    color: white !important;
    background: white;
    border-radius: 60px 60px 0px 0px;
  }
}

.tri {
  top: 25px !important;
}




