/* select>option {
padding: 6px 10px;
background-color: red;
margin-bottom: 10px !important  ;
} */

.data_ok {
  padding: 2px 30px;
  margin-right: 30px;
  color: var(--Gray-700, #344054);
  /* Text md/Semibold */
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition: 0.5s ease all;

  &:hover {
    color: var(--Gray-700, #fff);
    // transition: 0.5s ease all;
    background: var(--Base-White, #7f56d9);
    transition: 0.5s ease all;
  }
}

.eCHvUz > p > img {
  border-radius: 50% !important;
}

.report__box table th {
  /* Text xs/Medium */
  font-family: Inter, serif;
  font-size: 14px;
}

.dark__mode {
  .report__box .table_header {
    background: var(--Colors-Background-bg-primary, #0c111d);
    border-radius: var(--radius-xl, 12px 12px 0 0);
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .reportBox > div {
    border: 1px solid var(--Colors-Border-border-secondary, #1f242f) !important;
    background: var(--Colors-Background-bg-secondary, #161b26) !important;
    color: var(--colors-text-text-tertiary-600, #94969c) !important;
    /* Text xs/Medium */
  }
  .report__box table th {
    background: var(--Colors-Background-bg-secondary, #161b26) !important;
    color: var(--colors-text-text-tertiary-600, #94969c) !important;
    /* Text xs/Medium */
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .report__box td,
  .report__box tr {
    background: var(--Colors-Background-bg-primary, #0c111d);
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  .report__box select {
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background-color: var(--Colors-Background-bg-primary_alt, #161b26);
    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .report__box .employe_header_button-white {
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background-color: var(--Colors-Background-bg-primary_alt, #161b26);
    /* Shadows/shadow-xs */
    color: white;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .report__box .bpfbgi {
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
  }

  .react-datepicker,
  .react-datepicker__header {
    background-color: var(
      --Colors-Background-bg-primary_alt,
      #161b26
    ) !important;
    color: white !important;
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
  }

  .react-datepicker__header div,
  .react-datepicker__month div {
    color: white !important;
  }
}


