body {
    transition: background-color 0.5s, color 0.5s !important;
}

.mode_text {
    padding: 0;
    margin: 0;
    color: var(--colors-text-text-secondary-700, #344054);
    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    display: inline-block;
}

.modeBox {
    display: flex;
    gap: 15px;
}

.dark__mode .modeBox {
    background: var(--Colors-Background-bg-primary, #0C111D);
}

.dark__mode .mode_text {
    color: white;
}