.slick-slide {
  width: 280px !important;
  margin-right: 24px !important;
}
.calendar_op {
  border: 1px solid var(--Gray-200, #eaecf0) !important;
}
.operatorbox {
  margin: 0 0 20px 0;
}

.slick-list {
}

.slide_box {
  /* padding-left: 40px !important; */
}

.home_span {
  margin-bottom: 32px;
}

.slick-dots {
  display: none !important;
}

.home_aheader {
  display: flex !important;
  justify-content: space-between;
}

.home__label {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.home__inputs {
  border: none;
  outline: none;
}

.dark__mode {
  .link p {
    color: white !important;
  }

  .slick-arrow {
    display: none !important;
  }

  .operator__home .table_header {
  }

  .home__label {
    overflow: hidden;
    color: var(--colors-text-text-tertiary-600, #94969c);
    text-overflow: ellipsis;

    /* Text md/Regular */
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_alt, #161b26);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    .home__inputs {
      color: white;
      background: var(--Colors-Background-bg-primary_alt, #161b26);
    }
  }

  .table_header {
    border-radius: 12px 12px 0 0 !important;
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;

    background: var(--Colors-Background-bg-secondary, #161b26);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  > div {
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  tr {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;
    background-color: #161b26 !important;
  }

  .operatorbox > div {
    background-color: #161b26 !important;
  }

  tbody tr:nth-child(odd) {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;
    background: var(--Colors-Background-bg-secondary_hover, #1f242f) !important;
  }
  .operatorbox > div {
    border-radius: 12px;
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-secondary, #161b26);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .calendar_op {
    border-radius: 12px;
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
    background: var(--Colors-Background-bg-primary_alt, #161b26);
  }
}

.home_p {
  position: unset !important;
}
.home_p {
  position: fixed !important;
  top: 567px !important;
  & nav,
  & .MuiPagination-text {
    width: 58.9% !important;
    left: 20.2% !important;
  }
}

.operator__home .table_header {
  position: relative;
}

.operatorbox > div {
  position: relative;
  border-top: none !important;
}

.home_ps {
  position: unset !important;
  width: 99.5%;
  padding-right: 0;
}

.home_p {
  width: 100% !important;
  position: unset !important;
  border-top: none !important;
}

.home_ps .pagination {
  width: 100% !important;
  padding: 0;
}