.control_list {
  max-width: max-content;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 8px 8px 10px 8px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
}
.control_item {
  margin: 0;
  padding: 0;
  border-radius: 6px;
  cursor: pointer;
  /* Shadows/shadow-sm */
}
.control_link {
  margin: 0;
  padding: 0 !important;
  padding: 8px 12px !important;
  // padding: 8px 12px !important;
  text-decoration: none;

  // color: var(--Gray-500, #667085) !important;
  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 6px;
  line-height: 20px; /* 142.857% */
  &:hover {
    border-radius: 6px;
    background: var(--Base-White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    color: var(--Gray-700, #344054);
  }
  &:active {
    border-radius: 6px;
    background: var(--Base-White, #fff);
    /* Shadows/shadow-sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    color: var(--Gray-700, #344054);
  }
}

.control_link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: white;
  background-color: blue; /* Inactive state */
}

.control_name {
  margin: 0;
  color: #000;
  /* Text lg/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.control_header_button {
  width: 160px;
  padding: 10px 14px;
  border-radius: 8px;
  color: var(--Base-White, #fff);
  border: 1px solid var(--Brand-600, #7f56d9);
  background: var(--Brand-600, #7f56d9);
  // background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  // color: var(--Gray-700, #344054);
  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.control_list {
  gap: 2px;
}

.dark__mode {
  .bigBoxs .control_list {
    border-radius: 10px;
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_hover, #1f242f);
  }

  .control_link {
    color: white !important;
    background-color: transparent !important;
    transition: 0.2s ease;
  }

  .control_list .active {
    border-radius: 6px !important;
    background: var(--colors-foreground-fg-senary-300, #61646c) !important;

    /* Shadows/shadow-sm */
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
      0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
    color: white !important;
  }

  .control_name {
    color: white;
  }

  .control_table .table_header {
    border-radius: 10px 10px 0 0;
    // background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background: var(--Colors-Background-bg-primary, #0c111d) !important;
  }
  .hIaFKu {
    border: transparent;
  }

  .control_table table th {
    border-bottom: 1px solid var(--Colors-Border-border-secondary, #1f242f) !important;
    background: var(--Colors-Background-bg-secondary, #161b26) !important;
    color: var(--colors-text-text-tertiary-600, #94969c) !important;
    /* Text xs/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .control_table td,
  .control_table tr {
    background: var(--Colors-Background-bg-primary, #0c111d);
    border-bottom: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  .ipvTuL {
    border-radius: var(--radius-xl, 12px);
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .modal_overlay_main {
    background-color: #0c111d;
  }
  .modal_overlay_X {
    background-color: transparent;
  }

  .add_modal_title {
    color: white;
  }

  color: var(--colors-text-text-secondary-700, #cecfd2);

  .add_modal_input_text {
    /* Text sm/Medium */
    margin: 10px 0 5px 0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .add_modal_input {
    overflow: hidden;
    color: var(--Colors-Text-text-placeholder, #85888e);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary, #0c111d);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-bottom: 10px;
  }

  .add_modal_input_checkbox {
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary, #0c111d);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .control__box .control_item {
    margin: 0 !important;
  }
  .control__box .fAeXxV {
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
  }

  .bigBoxs table {
    background: var(--Colors-Background-bg-primary, #0c111d);
  }

  .control_table::-webkit-scrollbar {
    margin-left: 10px !important;
    width: 12px; /* Width of the scrollbar */
    border-radius: 10px;
  }

  .control_table::-webkit-scrollbar-track {
    margin-left: 10px !important;

    background: #1f242f; /* Background of the scrollbar track */
    border-radius: 10px;
  }

  .control_table::-webkit-scrollbar-thumb {
    margin-left: 10px;

    background: #0c111d; /* Background of the scrollbar handle */
    border-radius: 5px; /* Rounded corners of the scrollbar handle */
  }

  .control_table::-webkit-scrollbar-thumb:hover {
    background: #0c111d; /* Background of the scrollbar handle on hover */
  }
}
.control__box .control_item {
  margin: 0 !important;
}

.contr tr:nth-child(even) {
  background-color: white;
}

.control__box tr {
  border-bottom: 1px solid #eaecf0 !important;
}

.modal_overlay {
  position: fixed;
}

.control__box {
  margin: 0;
  padding: 24px 24px 24px 24px;
}

// .control_table {
//   overflow-y: scroll !important;
//   height: 380px;
// }
