.containerH {
  padding: 24px 24px 80px 24px;
}

.home_header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  background-color: #fff;
  align-items: center;
}
.home_header > h2 {
  margin: 0;
  color: #000;
  /* Display xs/Medium */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}
.home_header > label {
  position: relative;
}
.home_header > label > input {
  width: 292px;
  padding: 12px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding-left: 40px;
  &:focus {
    outline: none;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
.home_header > label > img {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}
.home_span {
  margin: 0;
  margin-top: 18px;
  height: 1px;
  background-color: #eaecf0;
}
.home_item {
  margin: 0;
  padding: 0;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

// DARKMODE

.dark__mode {
  & .containerH {
    background: var(--Colors-Background-bg-primary_alt, #161b26) !important;
  }
  & .home_header {
    background: var(--Colors-Background-bg-primary_alt, #161b26);
  }
  & .home_header > h2 {
    color: var(--colors-text-text-primary-900, #f5f5f6);
  }
  & .home_card {
    background: var(--Colors-Background-bg-primary_alt, #161b26) !important;
    border-radius: 12px;
    border: 1px solid var(--Colors-Border-border-primary, #333741);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  & .home_card p {
    color: var(--colors-text-text-primary-900, #f5f5f6);
  }

  & .home_span {
    background: var(--Colors-Background-bg-primary_alt, #161b26) !important;
  }

  & .sidebar {
    border-right: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);
  }

  & .sidebar_bottom_bot {
    border-top: 2px solid var(--Colors-Border-border-secondary, #1f242f);
    background: var(--Colors-Background-bg-primary, #0c111d);
  }
  & .sidebar_bottom_bot p {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }

  & .tableMOde > div {
    background: var(--Colors-Background-bg-primary_alt, #161b26);
  }

  & .table_header h3 {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }

  & table th {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }

  & tbody td {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }

  & .list__tr:nth-child(odd) {
    background: var(--Colors-Background-bg-secondary_hover, #1f242f) !important;
  }
  .iNjoKr {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;
  }

  & .kipxmS {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;
  }

  .tableMOde .table_header {
    background: var(--Colors-Background-bg-secondary, #161b26) !important;
  }

  .tableMOde > div {
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
  }

  & .iNjoKr {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d);
  }

  & .MuiTypography-root,
  & .MuiPickersDay-root {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }
  & .css-1g702h5-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border-radius: 20px;
    background: var(--Gray-100, #f2f4f7);
    color: #000 !important;
  }

  & .MuiPickersFadeTransitionGroup-root {
    color: var(--colors-text-text-primary-900, #f5f5f6) !important;
  }
  & .cal {
    border: 1px solid var(--Colors-Border-border-primary, #333741) !important;
  }

  & .css-1vooibu-MuiSvgIcon-root,
  & .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
    color: #94969c;
    margin-right: 1px;
  }
  .grtqHS {
    border-bottom: 0;
  }

  .tableMOde .table_header {
    // border-radius: 12px;
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;

    background: var(--Colors-Background-bg-secondary, #161b26);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .tableMOde .dYvkiF {
    border-bottom: 2px solid var(--Colors-Background-bg-primary, #0c111d) !important;
  }

  .tableMOde .QnhhC:nth-child(even) {
    background-color: #161b26 !important;
  }

  .studentsBox > div {
    border-radius: var(--radius-xl, 12px);
    border: 2px solid var(--Colors-Border-border-secondary, #1f242f) !important;
    background: var(--Colors-Background-bg-primary, #0c111d);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}


.kuzIzR {
  overflow: inherit !important;
}


// @media (min-width: 1479px) {
//   .admin_b > div:first-child > div:first-child {
//     overflow-y: scroll;
//     height: 300px;
//   }
//   .admin_b {
//     margin-bottom: 0;
//   }
// }

// @media (min-width: 1688px) {
//   .admin_b > div:first-child > div:first-child {
//     overflow-y: scroll;
//     height: 370px;
//   }
//   .admin_b {
//     margin-bottom: 0;
//   }
// }

// @media (min-width: 1920px) {
//   .admin_b > div:first-child > div:first-child {
//     overflow-y: scroll;
//     height: 470px;
//   }
//   .admin_b {
//     margin-bottom: 0;
//   }
// }

// /* Style for smaller viewports */
// @media (min-width: 2048px) {
//   .admin_b > div:first-child > div:first-child {
//     overflow-y: scroll;
//     height: 525px;
//   }
//   .admin_b {
//     margin-bottom: 0;
//   }
// }