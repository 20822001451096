.formik {
  width: 100% !important;
  max-width: 850px !important;
  padding: 24px 16px !important;
  margin: 0 auto !important;
}


.formik__title {
  margin: 0 0 12px 0;
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  font-family: sans-serif;
  color: #101828;
}

.formik__text {
  font-size: 18px;
  font-family: sans-serif;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 40px;
  color: #475467;
}

.formik__form > label {
  display: block;
}

.formik__form label {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  font-family: sans-serif;
  columns: black;
}

.form__input {
  display: block;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 14px 12px;
  /* max-width: 500px; */
  width: 95%;
  color: #667085;
  outline: none;
  border: 1px solid #D0D5DD;
  font-size: 16px;
}

.formik__btn {
  width: 98.5%;
  padding: 10px 0;
  background-color: #7F56D9;
  border-radius: 5px;
  border: none;
  color: white;
}

@media only screen and (max-width: 500px) {

  .formik__title {
    margin: 0 0 12px 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    font-family: sans-serif;
    color: #101828;
  }

  .formik__text {
    font-size: 16px;
    font-family: sans-serif;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 40px;
    color: #475467;
  }

  .formik__form {
  }

  .formik__form label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: sans-serif;
    columns: black;
  }

  .form__input {
    display: block;
    margin-bottom: 20px;
    margin-top: 6px;
    padding: 8px 12px;
    width: 95%;
    color: #667085;
    outline: none;
    border: 1px solid #D0D5DD;
  }

  .formik__btn {
    width: 100%;
    padding: 10px 0;
    background-color: #7F56D9;
    border-radius: 5px;
    border: none;
    color: white;
  }
}

.animation-overlay {
  position: fixed;
  border-radius: 10px;
  top: -100%;
  left: 10%;
  width: 80%;
  height: 100px;
  background: rgba(6, 236, 33, 0.448);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  font-family: sans-serif;
  transition: top 0.5s ease;
  z-index: 1000;
}

.animation-overlay.show {
  top: 45%;
}

.animation-overlay img {
  margin-right: 10px; /* Adjust as needed */
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
}
