body {
  overflow-x: hidden !important;
}

input {
  outline: none;
}

select {
  outline: none;
}

.admin_page {
  width: 100vh;
}


.sidebar {
  width: 19%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: var(--Colors-Background-bg-primary, #fff);
  border-radius: var(--radius-none, 0px);
  padding-top: 32px;
}

.sitebar_div {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  /* margin-top: 32px; */
  margin-bottom: 24px;
  padding: 12px 20px;
  width: 280px;
  height: 56px;
  cursor: pointer;
}

.sitebar_div>img:first-child {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.sitebar_div p {
  margin: 0;
  padding: 0;
  color: #344054;
  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
}

.sitebar_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sitebar_item {
  margin: 0;
  padding: 0;
}

.link,
.sidebar_bottom_top>p {
  margin: 4px 16px;
  padding: 8px 12px;
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #9197b3;
  background: #fff;
  padding: 12px;
  align-items: center;
  gap: 15px;
  transform: all 0.5s;
}

.link>img {
  width: 24px;
  height: 24px;
}

.link>p {
  align-items: center;
  color: var(--Colors-Text-text-secondary_hover, #182230);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.link:hover,
.sidebar_bottom_top>p:hover {
  border-radius: var(--radius-sm, 6px);
  background: var(--Colors-Background-bg-active, #f9fafb);
  transform: all 0.8s;
}


.active {
  border-radius: var(--radius-sm, 6px);
  background: var(--Colors-Background-bg-active, #f0f1f1);
  border: none;
}

.icon,
.link_text {
  /* font-size: 20px; */
  margin: 0;
}

/* ------------------------- */
.sidebar_bottom {}

.sidebar_bottom_top {
  display: block;
  /* display: flex; */
}

.sidebar_bottom_top>p {
  align-items: center;
  border: none;
  cursor: pointer;
}

.sidebar_bottom_bot {
  display: flex;
  justify-content: space-between;
  /* margin: 4px 16px; */
  padding: 12px 20px 12px 20px;
  border-radius: var(--radius-none, 0px);
  border-top: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  cursor: pointer;
}

.sidebar_bottom_bot>div {
  display: flex;
}

.sidebar_bottom_bot>div>img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.sidebar_bottom_bot>div>span>p {
  margin: 0;
  padding: 0;
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.sidebar_bottom_bot>div>span>p:first-child {
  color: var(--colors-text-text-secondary-700, #344054);
  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.sidebar_bottom_bot>button {
  border: none;
  background-color: #fff;
}

.sidebar_bottom_bot>button>img {
  padding: 0;
  margin: 0;
  width: 20px;
}

.sidebar_bottom_bot {
  transition: 0.2s ease;
  align-items: center;
}

.pagination {
  margin-left: 2px;
}

.sidebar__logout {
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s ease;
}

.sidebar__logout:hover {
  background: #a19d9d8a;
}



/* DARKMODE */

.dark__mode .sidebar {
  background: var(--Colors-Background-bg-primary, #0C111D);
}

.dark__mode .link {
  background: var(--Colors-Background-bg-primary, #0C111D);
  transition: 0.2s ease;
}

.dark__mode .link:hover {
  background: var(--Colors-Background-bg-active, #1F242F);
  color: white !important;
}

.dark__mode .sidebar_bottom_top>p {
  background: var(--Colors-Background-bg-primary, #0C111D) !important;
}

.dark__mode .active {
  background: var(--Colors-Background-bg-active, #1F242F);
  color: white !important;
}

.dark__mode .link_text {
  color: white !important;
}

.dark__mode .settings__text {
  color: white;
}

.dark__mode .sidebar__logout:hover {
  background-color: #ffffff00;
}

.dark__mode .bigBoxs {
  background-color: var(--Colors-Background-bg-primary_alt, #161b26) !important;
}

.sidebar__logout {
  background: #a19d9d29;
}

.dark__mode .sidebar_bottom_top>p:hover {
  background-color: var(--Colors-Background-bg-active, #1F242F) !important;
}

.dark__mode .modeBox {
  color: white !important;
  background-color: transparent !important;
}

.kuzIzR {

  overflow-y: scroll;
}


.dark__mode input {
  color: white !important;
}


.dark__mode::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.dark__mode::-webkit-scrollbar-track {
  background: #344054;
  /* Background of the scrollbar track */
}

.dark__mode::-webkit-scrollbar-thumb {
  background: #161b26;
  /* Background of the scrollbar handle */
  /* border-radius: 10px; */
  /* Rounded corners of the scrollbar handle */
}

.dark__mode::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Background of the scrollbar handle on hover */
}