.textarea__form::placeholder {
  color: white !important;
}

.react-kanban-board {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* Enable vertical scrolling if needed */
  overflow-x: auto;
  /* Enable horizontal scrolling if needed */
}

/* Custom scrollbar styles */
.react-kanban-board::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

.react-kanban-board::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.react-kanban-board::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 10px;
  border: 3px solid #f0f0f0;
}

.react-kanban-board::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(4.5rem + calc(var(--bs-border-width) * 2)) !important;
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.tri {
  position: absolute;
  right: 30px;
  top: 16px;
  width: 20px;
  transition: 0.4s ease;
}

.textarea-animation img {
  transition: transform 0.3s ease;
}

/* Add styles for the animation when the textarea is focused */
.textarea-animation:focus-within img {
  transform: rotate(180deg);
}

.btn__ok {
  position: absolute;
  bottom: -150px;
  left: 172px;
  padding: 1px 14px;
  border-radius: 6px;
  background: #5151de;
  font-weight: bold;
  outline: none;
}

.dark__mode {
  .kanba__box {
    label {
      overflow: hidden;
      color: var(--colors-text-text-tertiary-600, #94969c);
      text-overflow: ellipsis;

      /* Text md/Regular */
      font-family: Inter, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      border-radius: var(--radius-md, 8px);
      background: var(--Colors-Background-bg-primary_alt, #161b26);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      input {
        color: white;
        border: 2px solid var(--Colors-Border-border-primary, #333741) !important;

        background: var(--Colors-Background-bg-primary_alt, #161b26);
      }
    }
    .react-kanban-board::-webkit-scrollbar-track {
      background: #333741;
      border-radius: 10px;
    }

    .react-kanban-board::-webkit-scrollbar-thumb {
      background-color: #161b26;
      border-radius: 10px;
      border: 3px solid #333741;
      transition: 0.2s ease;
    }

    .react-kanban-board::-webkit-scrollbar-thumb:hover {
      background-color: #161b26;
    }
  }
}
.kanban-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ekran balandligiga moslashtiradi */
  width: 100vw; /* Ekran kengligiga moslashtiradi */
  overflow: hidden; /* Ishlatilmaydigan joyni yashirish */
}

.kanban-scroll {
  width: 100%; /* Kenglikni ekranga moslashtirish */
  height: 100%; /* Balandlikni ekranga moslashtirish */
  overflow: auto; /* Scroll qo'shish */
}

