.students_forms {
  width: 160vh;
}

.students_forms_title {
  padding: 0 0 17px 0;
  // border-bottom: 1px solid var(--Gray-200, #eaecf0);
}
.feedback_employee_box{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 10%;
}
.students_forms_height {
  height: 60vh;
  overflow: auto;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  border-top: 1px solid var(--Gray-200, #eaecf0);
}

.students_forms_box1 {
  display: flex;
  align-items: center;
  min-width: 900px;
  // align-items: start;
  justify-content: space-between !important;
  padding: 15px 0 0 0 !important;
  margin: 0;
}

.students_forms_boxTitle {
  flex: 1;
}

.students_forms_inputBox {
  flex: 2;
  display: flex;
  justify-content: center;
}

.students_forms_label {
  display: flex;
  flex-direction: column;
}

.students_form_inputs,
.header_select {
  width: 320px;
  color: #000;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #d0d5dd;
}
.setting_person_form_bottom {
  margin-top: 0;
}
.modal_active {
  border-radius: 6px;
  background: var(--Base-White, #fff) !important;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 1px 2px 0 rgba(16, 24, 40, 0.06);
  color: var(--Gray-700, #344054);
}
.modal_def {
  margin: 4px 16px;
  text-decoration: none;
  display: flex;
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  padding: 12px;
  align-items: center;
  transform: all 0.5s;
  border-radius: 10px;
  background: var(--Gray-50, #f9fafb);
}
.modal_def > p {
  font-family: Inter, serif;
  font-size: 14px;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

// -------------------------------Scroll dizay-------------------------------

/* WebKit browsers (Chrome, Safari) */
.students_forms_height::-webkit-scrollbar {
  width: 8px;

  &-thumb {
    background: #888;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
}

.students_forms_height::-webkit-scrollbar-thumb {
  background: #fffb00;
  border-radius: 10px !important;
  border: 2px solid #0d1fc0;
}

.students_forms_height::-webkit-scrollbar-thumb:hover {
  background: #0047cc;
}

.students_forms_height::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Firefox */
.students_forms_height {
  scrollbar-width: thin;
  scrollbar-color: #f1f1f1;
  border-radius: 10px !important;
}

.students_forms {
  padding: 15px 15px 0;
}

.students_forms_height {
  max-height: 400px;
  overflow-y: auto;
}

/* General form input and select styles */
.students_form_inputs,
.header_select {
  width: max-content;
  padding: 7px 10px !important;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #d0d5dd !important;
}

// .input-file {
// 	position: relative;
// 	display: inline-block;
// }
// .input-file-text {
// 	padding: 0 10px;
// 	line-height: 40px;
// 	text-align: left;
// 	height: 40px;
// 	display: block;
// 	float: left;
// 	box-sizing: border-box;
// 	width: 200px;
// 	border-radius: 6px 0px 0 6px;
// 	border: 1px solid #ddd;
// }
// .input-file-btn {
// position: relative;
// 	display: inline-block;
// 	cursor: pointer;
// 	outline: none;
// 	text-decoration: none;
// 	font-size: 14px;
// 	vertical-align: middle;
// 	color: rgb(255 255 255);
// 	text-align: center;
// 	border-radius: 0 4px 4px 0;
// 	background-color: #419152;
// 	line-height: 22px;
// 	height: 40px;
// 	padding: 10px 20px;
// 	box-sizing: border-box;
// 	border: none;
// 	margin: 0;
// 	transition: background-color 0.2s;
// }
// .input-file input[type=file] {
// 	position: absolute;
// 	z-index: -1;
// 	opacity: 0;
// 	display: block;
// 	width: 0;
// 	height: 0;
// }

// /* Focus */
// .input-file input[type=file]:focus + .input-file-btn {
// 	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
// }

// /* Hover/active */
// .input-file:hover .input-file-btn {
// 	background-color: #59be6e;
// }
// .input-file:active .input-file-btn {
// 	background-color: #2E703A;
// }

// /* Disabled */
// .input-file input[type=file]:disabled + .input-file-btn {
// 	background-color: #eee;
// }

.file-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.file-name {
  display: block !important;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px; /* Закругление левых краев */
  min-width: 200px;
}

.file-input {
  display: none;
}

.file-label {
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background: #f2f4f7;
  &:hover{
    background: #f7e4a8;
  }
  cursor: pointer;
}
.file-label-svg{
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background: #f2f4f7;
  &:hover{  
  background: rgb(248, 188, 188);
  }
}

.file-label:hover {
  background: #bcf39c;
}
.file-label-down{
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  background: #f2f4f7;
  &:hover{  
  background: rgb(138, 193, 224);
  }
}

.file-icon {
  width: 30px;
  margin-right: 3px;
  color: #131a25;
  /* Text md/Semibold */
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}


.dark__mode {
  .students__box {
    label {
      overflow: hidden;
      color: var(--colors-text-text-tertiary-600, #94969c);
      text-overflow: ellipsis;

      /* Text md/Regular */
      font-family: Inter, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      border-radius: var(--radius-md, 8px);
      background: var(--Colors-Background-bg-primary_alt, #161b26);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      input {
        color: white;
        border: 2px solid var(--Colors-Border-border-primary, #333741) !important;

        background: var(--Colors-Background-bg-primary_alt, #161b26);
      }
    }
  }

  .op_big {
    display: flex;
    align-items: center;
  }

  .control_list {
    border-radius: 10px;
    border: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary_hover, #1f242f) !important;
  }

  .modal_active {
    color: white;
    border-radius: 6px;
    background: var(--colors-foreground-fg-senary-300, #61646c) !important;

    /* Shadows/shadow-sm */
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1),
      0 1px 2px 0 rgba(16, 24, 40, 0.06);
  }

  .modal_def {
    border-radius: 6px;
    background-color: transparent;
  }

  .students__box select {
    border-radius: var(--radius-md, 8px);
    border: 1px solid
      var(
        --Component-colors-Components-Buttons-Secondary-button-secondary-border,
        #333741
      );
    background: var(
      --Component-colors-Components-Buttons-Secondary-button-secondary-bg,
      #161b26
    );

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  .students_forms_height {
    border: none;
  }

  .students_forms_box1:nth-child(1) {
    border-top: none;
  }

  .students_forms_box1 {
    border-bottom: 1px solid #333741;
  }
  .students_forms_title {
    border-bottom: 1px solid #333741;
  }

  .students_forms_inputBox {
    padding: 0 15px 0 0;
    justify-content: end;
  }
  .students_forms_inputBox .students_forms_label {
    background-color: transparent;
  }

  .students_forms_inputBox .header_select {
    padding: 10px !important;
  }

  .students_forms_box1:nth-child(3) .students_forms_inputBox {
    padding: 0 10px 0 0;
  }

  .file-input-container input {
    overflow: hidden;
    color: var(--Colors-Text-text-placeholder, #85888e);
    text-overflow: ellipsis;

    /* Text md/Regular */
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-radius: var(--spacing-md, 8px) var(--spacing-none, 0px)
      var(--spacing-none, 0px) var(--spacing-md, 8px);
    border-top: 1px solid var(--Colors-Border-border-primary, #333741);
    border-bottom: 1px solid var(--Colors-Border-border-primary, #333741);
    border-left: 1px solid var(--Colors-Border-border-primary, #333741);
    background: var(--Colors-Background-bg-primary, #0c111d);
  }

  .students_forms_inputBox {
    justify-content: end !important;
  }
  .modal_overlay_main::-webkit-scrollbar-track {
    background: #333741;
    border-radius: 10px;
  }

  .modal_overlay_main::-webkit-scrollbar-thumb {
    background-color: #161b26;
    border-radius: 10px;
    border: 3px solid #333741;
    transition: 0.2s ease;
  }

  .modal_overlay_main::-webkit-scrollbar-thumb:hover {
    background-color: #161b26;
  }

  .studentsBox tr:nth-child(even) {
    background-color: #161b26 !important;
  }

  .admin .students_forms {
    padding: 0 !important;
  }
}

// .students__box .modal_overlay {
//   position: fixed;
// }

// .students__box .modal_overlay_main {
//   max-height: 680px !important;
//   height: 100% !important;
// }

.students__box select {
  padding: 0 10px ;
}

.control_item {
  margin: 5px 0;
}

.modal_def {
  padding: 7px;
}

/* Styling for WebKit browsers (Chrome, Safari) */
.modal_overlay_main::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.modal_overlay_main::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

.modal_overlay_main::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #f1f1f1; /* Space around the thumb */
}

.modal_overlay_main::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
@media (min-width: 1519px) {
  .pagination_c {
    top: 630px;
    height: 65px;
  }
}

@media (min-width: 1688px) {
  .pagination_c {
    top: 708px !important;
    height: 65px;
  }
}

@media (min-width: 1920px) {
  .pagination_c {
    top: 804px !important;
    height: 65px;
  }
}

/* Style for smaller viewports */
@media (min-width: 2048px) {
  .pagination_c {
    top: 863px !important;
    height: 65px;
  }
}

