.contract_div {
  display: flex;
  align-items: flex-end;
}
.contract_button {
  margin: 0;
  padding: 10px 25px;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 40px;
  background: var(--Brand-600, white);
}
.contract_colorX {
  color: rgb(16, 180, 10);
  border: 2px solid rgb(16, 180, 10);
}
.contract_colorY {
  color: red;
  border: 2px solid red;
}

.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.chat-window {
  width: 750px;
  height: 420px;
  margin: 0 auto;
  border-radius: 35px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.chat-window .chat-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 415px;
  background: #fff;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-footer {
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.chat-window .chat-footer .control_header_button {
  position: relative;
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 25px;
  color: lightgray;
}
.chat-footer {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
}

.chat-footer textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  max-height: 100px;
  scrollbar-width: thin;
  outline: none;
  font-size: 16px;
  line-height: 1.3;
}

.hide {
  opacity: 0 !important;
}
.chat-header {
  position: relative;
  height: 55px;
  border-radius: 0 0 0 0;
  background-image: url("../../../Img/chat_header-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.chat_header_img {
  margin-left: 10px;
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  z-index: 1;
}

.chat_employee {
  margin-left: 12%;
  display: flex;
  padding-top: 3%;

}
.chat_emloyee_fullname {
  margin: 0;
  color: var(--Gray-900, #101828);
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.chat_emloyee_role {
  margin: 0 0 4px;
  color: var(--Gray-600, #475467);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.message-item {
  padding: 3px 12px;
  font-size: 12px;
  margin: 5px 0;
  background-color: #e1f5fe;
  color: #0277bd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.message-item:hover {
  background-color: #b3e5fc;
}

.message-item.sent {
  background-color: #d4edda;
  color: #155724;
  max-width: 60%;
  border-radius: 8px 8px 0 8px;
  width: max-content;
  align-self: end;
}

/* Received Messages */
.message-item.received {
  background-color: #cce5ff;
  color: #004085;
  max-width: 60%;
  border-radius: 8px 8px 8px 0;
  width: max-content;
}

.message-body {
  width: 95%;
  height: 340px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 0 !important;
}

.message-item {
  white-space: pre-wrap;
}

.message-time {
  display: block;
  font-size: 10px;
  color: #888;
  text-align: right;
}
.date-separator {
  text-align: center;
  font-size: 10px;
  color: #888;
}
.highlight {
  background-color: yellow; /* Change to your desired color */
  font-weight: bold;
}

.scroll-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



